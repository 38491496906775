export const translations = {
  en: {
    translation: {
      button: {
        continue: 'Continue',
        loading: 'Loading...',
        notValidExchange: 'Exchange not possible',
        addNewFeeRange: '+ Add New Fee Range',
        configureFeeRanges: 'Configure Fee Ranges',
        createBalance: 'Create Balance',
        viewAllPayments: 'View all Payments',
        paymentsHistory: 'Payments history',
        generateInviteLink: 'Generate Invite link',
        copy: 'Copy',
        copied: 'Copied',
        cancel: 'Cancel',
        confirmAndCreateChat: 'Confirm and create chat',
        linkToTelegramChat: 'Link to telegram chat',
        shareProvider: 'Share this provider',
        signIn: 'Sign in',
        makeAnAssistant: 'Make an assistant',
        clearFilters: 'Clear all',
        balances: 'Balances',
        addNewContact: '+ Add new contact',
        receive: 'Receive',
        request: 'Request',
        send: 'Send',
        sending: 'Sending',
        receiving: 'Receiving...',
        requesting: 'Requesting...',
        close: 'Close',
        delete: 'Delete',
        edit: 'Edit',
        confirm: 'Confirm',
        yes: 'Yes',
        no: 'No',
        deleteContact: 'Delete contact',
        areYouSure: 'Are you sure?',
        loginViaBot: 'Log In to start',
        confirmViaBot: 'Confirm via Telegram',
        stopBeingCustomer: 'Stop being a customer',
        stopBeingProvider: 'Stop being a provider',
        offerNotAccepted: 'Exchange has not been accepted in time',
        offerAccepted: 'Join Telegram Chat',
        noLink: 'Could not find telegram link',
        sendMessageWA: 'Send message via WhatsApp',
        transfer: 'Transfer',
        transferred: 'Transferred',
        transferring: 'Transferring',
        contactMe: 'Contact me',
        whatsapp: 'WhatsApp',
        other: 'Other',
        setupNewDeal: 'Setup new deal',
        getPrice: 'Get price via the API',
      },
      status: {
        sureQuestion: 'Are you sure?',
        authorizing: 'Authorizing...',
        adding: 'Adding...',
        saving: 'Saving...',
        creating: 'Creating...',
        deleting: 'Deleting...',
        archiving: 'Archiving...',
        processing: 'Processing...',
        success: 'Success',
        userDeleted: 'User was deleted',
        settingsSaved: 'Settings have been saved',
        successCreatedBalance: 'New balance is created successfully',
        successChangeBalance: 'Information has been updated',
        successDeletedBalance: 'Balance has been deleted',
        successArchivedBalance: 'Balance has been archived',
        successAddedAssistant: 'Assistant has been added',
        successDeletedAssistant: 'Assistant has been deleted',
        successSubscribed: 'Subscribed',
        smthWrong: 'Something went wrong',
        aiPrioritizing: 'AI prioritizing',
        deleted: 'Deleted',
        copied: 'Copied to clipboard',
      },
      tab: {
        exchangeHistory: 'Exchange History',
        balanceHistory: 'Balance History',
        invitationsOptions: 'Invitations options',
        invitationsHistory: 'Invitations history',
        alreadyReceived: 'Already received',
        wantToReceive: 'Want to receive',
      },
      labels: {
        youGive: 'You Give',
        youGet: 'You Get',
        youProvideTo: 'You Provide To',
        profit: 'Profit',
        totalIncome: 'Total Income',
        cash: 'Cash',
        transfer: 'Transfer',
        goods: 'Goods',
        currency: 'Currency',
        rent: 'Rent',
        role: 'Your Role',
        status: 'Status',
        betterExchangeRate: 'better rate {{ rate }}',
        betterExchangeFrom: 'starting at {{ amount }} {{ code }}',
        toArchive: 'to archive',
        total: 'Total',
        clientGives: 'Client gives',
        clientGets: 'Client gets',
        title: 'Title',
        comment: 'Comment',
        type: 'Type',
      },
      placeholder: {
        comment: 'Type your comment...',
      },
      error: {
        comment: 'Enter comment',
        value: 'Enter amount',
        code: 'Enter currency code',
        unknown: 'Error occurred',
      },
      menu: {
        history: 'History',
        home: 'Home',
        exchange: 'Deals',
        friends: 'My Circle',
        profile: 'Projects',
        back: 'Back',
        task: 'TaskFlow',
      },
      component: {
        provider: 'Provider',
        yourProvider: {
          title: 'Your provider',
          empty: "You don't have provider",
        },
        exchangeProvider: 'Exchange Provider',
        assistants: {
          empty: 'You have no assistants',
        },
        providersSlider: {
          rideTitle: 'Take your',
          ride: 'ride',
          text: 'your exchange provider',
          yourOffer: 'Here you can see the offer that you are forming for your customers',
        },
        customerLanguage: {
          title: 'Customer language',
          optional: 'optional',
          list: {
            unspecified: 'Unspecified',
            english: 'English',
            russian: 'Russian',
          },
        },
        reusableInvite: {
          title: 'Group invite'
        },
        invitation: {
          createdAt: 'Created at',
          inviteCode: 'Invite code',
          accepted: 'Accepted',
          expired: 'Expired',
          recommendation: 'Recommendation',
          remainingTime: 'Remaining time',
          emptyList: "Clicking the button will generate an invitation code. You need to copy this code and send it along with the link to the app to your contact via any messenger.",
          copied: 'Copied',
        },
        balanceSummary: {
          with: 'with {{name}}',
          deligated: 'delegated by {{name}}',
        },
        transaction: {
          added: 'added by {{name}}',
        },
      },
      date: {
        days: 'days',
        today: 'Today',
        January: 'January',
        February: 'February',
        March: 'March',
        April: 'April',
        May: 'May',
        June: 'June',
        July: 'July',
        August: 'August',
        September: 'September',
        October: 'October',
        November: 'November',
        December: 'December',
      },
      time: {
        short: {
          hour: 'h',
          minute: 'm',
        },
      },
      page: {
        main: {
          step1: '1. Copy Your invitation code',
          invitationCodeCopied: 'Invitation code copied',
          step2Desktop: '2. Log in with telegram',
          step2Touch: '2. Install the app',
          step3: '3. Go to My Circle <arrow/> <tag/>Redeem invitation',
        },
        provider: {
          title: 'Provider',
          trustMoneyInfo: 'How much money you’d trust {{providerName}}?',
          trustMoneyDescription: 'Choose the amount of money to rate your trust',
        },
        exchange: {
          title: 'Deals',
        },
        exchangeInfo: {
          title: 'Confirmation',
          receptionAccount: {
            title: 'Reception Account',
            placeholder:
              'Here you can enter your account information (e.g. credit card, phone number, crypto wallet)',
          },
          preparedText: 'I have {{currency}} prepared and ready',
          deadlineLabel: 'Receive Deadline',
          goods: {
            title: 'Confirmation',
            in: 'in',
            city: 'City',
          },
          provider: 'Provider',
          timer: 'Offer will be relevant',
          alreadyCreated: 'Exchange already requested',
          customer: 'Customer',
          inviteLink: 'Invite link',
          contact: 'Contact'
        },
        success: {
          title: 'Exchange Requested',
          tmInfo: 'Your Telegram chat with provider has been created automatically.',
          tmInfoButton: 'Click the button below to enter the chat and discuss transaction details.',
        },
        friends: {
          title: 'My Circle',
          empty: 'Connect App is an invitation-only social network. Add everyone around you here to keep your money balances, deals, and tasks in order with them. Generate an invitation using the "+" button at the top of the screen.',
          search: 'Search...',
        },
        contactProfile: {
          title: 'Contact Profile',
          feeRanges: {
            title: 'Fee Ranges',
            feeRatesTable: {
              caption: 'Click on cell to edit',
              thead: {
                lowerBound: 'Lower bound',
                feeRate: 'Fee rate',
              },
            },
          },
          balancesHistory: {
            emptyListPlaceholder: "Nothing here yet"
          },
          paymentsHistory: {
            emptyListPlaceholder: "Nothing here yet"
          }
        },
        payments: {
          title: 'History',
          empty: 'List is empty',
        },
        paymentInfo: {
          title: 'Payment',
          infoTab: 'Info',
          historyTab: 'History',
          titleId: 'Title',
          id: 'Payment ID',
          status: 'Payment Status',
          providerFeeRate: 'Provider fee rate',
          providerFeeAmount: 'Provider fee amount',
          creationDate: 'Payment creation date',
          deadline: 'Payment deadline',
          customer: 'Customer',
          requester: {
            gives: 'Customer gives',
          },
          provider: {
            gives: 'Provider gives',
          },
          chatHistory: 'Chat history',
        },
        paymentChatHistory: {
          notFound: 'Payment chat history not found',
        },
        profile: {
          title: 'Projects',
          menu: {
            uploadCurrencyFile: 'Upload currency file',
            upgradeToProvider: 'Upgrade to provider',
            maintenance: 'Maintenance',
            allPayments: 'All payments',
            changeLanguage: 'Choose language',
            logout: 'Logout',
            redeemInvitation: 'Redeem invitation',
            feeRangeDefaults: 'Default fee ranges',
            assistants: 'Assistants',
            subscriptions: 'Push-subscriptions',
            deleteAccount: 'Delete account',
            notifications: 'Notifications',
            exchangeRates: 'Exchange rates',
            myFees: 'My fees',
            myOffers: 'My offers',
            tasks: 'Tasks',
          },
          noProjects: "You don't have any projects yet"
        },
        invitations: {
          title: 'Invitations',
          shareButton: 'Share invite',
          shareText: 'Invitation link to Connect✨App'
        },
        redeemInvitation: {
          title: 'Redeem invitation',
          button: 'Redeem',
          inputPlaceholder: 'Invitation code',
          successMessage: 'Code redeem is successful',
        },
        feeRangesDefault: {
          title: 'Default fee ranges',
        },
        login: {
          pasteInviteCode: 'Paste invite code',
          helpText:
            'Please, press button above to paste your invite code (if you have one) or enter it manually',
        },
        assistants: {
          mainAssistants: 'Main assistants',
          noAssistants: 'No assistants',
        },
        exchangeRates: {
          title: {
            main: 'Exchange rates',
            you: 'You',
            availableProviders: 'Available providers',
          },
          loading: 'Loading...',
          empty: 'You have no providers',
          provider: {
            empty: 'This provider have no rates',
          },
          resellStrategy: 'Resell strategy',
        },
        subscriptions: {
          title: 'Push-subscriptions',
        },
        balanceAssistants: {
          title: 'Balance assistants',
          currentAssistants: 'Current assistants',
          otherContacts: 'Other contacts',
          currentAssistantsEmpty: 'No assistants',
          contactsNotFound: 'Contacts not found',
          search: 'Search...',
        },
        balanceHistory: {
          title: 'Balance history',
        },
        task: {
          header: {
            button: 'Add task to AI',
          },
          mainTask: {
            noTasksTitle: 'Your most important task will always be here',
            noTaskDescription: 'Add 2 or 3 tasks at once into AI so it can choose first to start. Try to send all your tasks to AI first, as it knows all the "Jedi" practices and will suggest the correct sequence. This will help avoid wasting time on secondary tasks and their selections, keeping you always focused on the main thing',
            createButton: 'Add any first task to AI',
            prioritizeButton: 'Show the most important',
            reprioritizeButton: 'Prioritize more accurately',
            prioritizeButtonDisabled: 'AI prioritization...',
            aiPrioritizedTitle: 'AI prioritized',
            aiPrioritizedDescription: 'Your move now',
            notPrioritizedTasksTitle: 'Number of tasks in the line: ',
            notPrioritizedTasksDescription: 'Click the button below to see the next most valuble task',
          },
          nextTask: 'See all tasks',
          seeAll: 'See all',
          list: {
            draft: 'Draft tasks',
            recently: 'Recently added',
            blocked: 'Blocked tasks',
            unblock: 'Unblock',
            setTask: 'Set task',
            prioritize: 'Prioritize',
            transactional: 'Transactional tasks',
            delegated: 'Delegated tasks',
            emptyRecentlyAdded: "You don't have any recently added tasks"
          }
        },
        taskHistory: {
          title: 'Task history',
          empty: 'Nothing here yet'
        },
        taskInfo: {
          originalDescription: 'Original Description',
          description: 'Description',
          aiProposed: 'AI proposed',
          aiPrioritizedDescription: 'Your task now',
          whyImportantNow: 'Why important now?',
          postponeComment: 'Reason for blocking',
          status: 'Status',
          implementer: 'Implementer',
          reviewer: 'Reviewer',
          statuses: {
            inProgress: 'In progress',
            readyForWork: 'Ready for work',
            draft: 'Draft',
            blocked: 'Blocked',
            done: 'Done'
          },
          buttons: {
            delete: 'Delete',
            postpone: 'Mark as blocked',
            unblock: 'Unblock',
            addNewTask: 'Add new task',
            setTask: 'Set task',
            setTaskAssessment: 'AI assessment...',
            complete: 'Complete',
            close: 'Close',
            stillInProgress: 'Still in progress',
            takeOn: 'Take on task',
            delegate: 'Delegate task'
          }
        },
        tasks: {
          tabNextTasks: 'Next tasks',
          tabDelegatedTasks: 'Delegated tasks',
          tabCompleted: 'Completed',
          emptyNextTasks: "You don't have the following tasks",
          emptyDelegatedTasks: "You don't have the delegated tasks",
          emptyCompletedTasks: "You don't have any completed tasks",
          aiPrioritized: 'AI prioritized',
          addTaskButton: 'Add task',
          unprioritized: 'Unprioritized',
          blocked: 'Blocked',
          toBeFormulated: 'To be formulated',
        },
        notifications: {
          title: 'Notifications',
          switchOff: 'Switch everything off',
          type: 'Notification type',
          bot: 'Bot',
          push: 'Push',
          balances: 'Balances',
          exchanges: 'Exchanges',
          invitations: 'Invitations',
          bulk: 'System',
          task: 'Task'
        }
      },
      install: {
        pwa: 'Install PWA (Progressive Web App)',
        yourCode: 'your invitation code',
        copy: 'Copy the invitation code',
        openSafari: 'Please open Safari now',
        tapOnShareButton: 'Push "Share" button now',
        hint: 'Please, copy the invitation code.',
        installPWA: 'Install the app',
        alreadyInSafari: "I'm already in Safari",
        instruction: 'Instructions for installing<br><strong>Connect PWA</strong>',
        step1: '1. Open this page in Safari',
        step2: '2. Tap Share',
        step3: '3. Tap <strong>Add to Home Screen</strong>',
        step4: '4. Tap <strong>Add</strong>',
        video: 'Video instruction',
        skip: 'skip installation',
      },
      rentButton: {
        day: 'day',
        days: 'days',
      },
      resellStrategy: {
        default: 'Default',
        chainPayments: 'Chain payments',
        squashPayments: 'Squash payments',
      },
      modal: {
        balanceInfo: {
          emptyNameError: 'Name cannot be empty',
          namePlaceholder: 'Type your new balance name...',
          saveButton: 'Save',
          cancelButton: 'not now',
        },
        balanceRemove: {
          questionDelete: 'Are you sure you want to delete "{{balanceName}}"?',
          questionArchive: 'Are you sure you want to archive "{{balanceName}}"?',
        },
        obligationTransfer: {
          selectRecipient: 'Select recipient',
          empty: 'Recipients not found',
          error: 'Problem with balance {{balanceName}}, id={{id}}, cannot find other balance party',
        },
        callMeBack: {
          title: 'Complete form<br>for the callback',
          thanks: 'Thanks!',
          thanksDescription:
            'Our representative will call you soon and advise you on your questions. In the meantime, you can check out our Instagram and X',
          policyPrivacy: 'Agree to our <a>Terms of Service<br>and Privacy Notice</a>',
          button: 'Call me',
          loadingButton: 'Sending',
          namePlaceholder: 'Your name',
          phonePlaceholder: 'Phone',
        },
        update: {
          title: 'Found a new version of app',
          description: 'You are using an outdated version, please update the app',
          updateButton: 'Update',
          closeButton: 'Close',
        },
        taskFormulation: {
          cancelButton: 'Cancel',
          createDraftButton: 'Save draft task',
          initialCase: 'Describe the task in general words',
          nextButton: 'Next',
          confirmButton: 'Save',
          qAndA: 'Questions and answers'
        },
        stillInProgress: {
          closeButton: 'Close',
          nextButton: 'Next',
          success: 'Successfully',
          postponedTo: 'Postponed until: '
        },
        createPaymentInvitation: {
          chooseContact: 'Choose contact',
          confirmation: 'Confirmation',
          shareInvite: 'Share invite',
          copied: 'Copied',
          createInvite: 'Create invite',
          inviteWithLink: 'Invite with link',
          close: 'Close',
          cancel: 'Cancel',
          empty: 'Not found customers',
        },
        postponeTask: {
          description: 'Enter the reason for the blocking',
          confirmButton: 'Confirm',
          cancelButton: 'Cancel',
          errorMessage: 'Fill out a comment'
        },
        delegateTask: {
          chooseContact: 'Choose contact',
          closeButton: 'Close',
          success: 'Successfully'
        }
      },
      notification: {
        subscribe: 'Subscribe',
        unsubscribe: 'Unsubscribe',
        notPwa: 'This applications in not PWA',
        denied: 'Notifications are denied',
        notSupported: 'Notifications are not supported',
        banner: 'Allow Push-notifications',
      },
    },
  },
  ru: {
    translation: {
      button: {
        continue: 'Продолжить',
        loading: 'Отправка...',
        notValidExchange: 'Обмен невозможен',
        addNewFeeRange: '+ Добавить новый размер комиссии',
        configureFeeRanges: 'Настроить размеры комиссий',
        createBalance: 'Создать баланс',
        viewAllPayments: 'Все платежи',
        paymentsHistory: 'История платежей',
        generateInviteLink: 'Сгенерировать приглашение',
        copy: 'Скопировать',
        copied: 'Скопировано',
        cancel: 'Отмена',
        confirmAndCreateChat: 'Подтвердить и создать чат',
        linkToTelegramChat: 'Ссылка в телеграм чат',
        shareProvider: 'Поделиться провайдером',
        signIn: 'Войти',
        makeAnAssistant: 'Сделать помощником',
        clearFilters: 'Сбросить фильтр',
        balances: 'Балансы',
        addNewContact: '+ Добавить новый контакт',
        receive: 'Получить',
        request: 'Запросить',
        send: 'Отправить',
        sending: 'Отправляем',
        receiving: 'Получаем...',
        requesting: 'Запрашиваем...',
        close: 'Закрыть',
        delete: 'Удалить',
        edit: 'Изменить',
        confirm: 'Подтверждаю',
        yes: 'Да',
        no: 'Нет',
        deleteContact: 'Удалить контакт',
        areYouSure: 'Вы уверены?',
        loginViaBot: 'Войти',
        confirmViaBot: 'Подтвердить через Telegram',
        stopBeingCustomer: 'Перестать быть кастомером',
        stopBeingProvider: 'Перестать быть провайдером',
        offerNotAccepted: 'Обмен не был принят вовремя',
        offerAccepted: 'В телеграм чат',
        noLink: 'Не найдена ссылка в чат',
        sendMessageWA: 'Написать в WhatsApp',
        transfer: 'Перевести',
        transferred: 'Переведено',
        transferring: 'Переводим...',
        contactMe: 'Свяжитесь со мной',
        whatsapp: 'WhatsApp',
        other: 'Другой способ',
        setupNewDeal: 'Создать новую сделку',
        getPrice: 'Получить цену через API',
      },
      status: {
        sureQuestion: 'Вы уверены?',
        authorizing: 'Авторизуем...',
        adding: 'Добавляем...',
        saving: 'Сохраняем...',
        creating: 'Создаём...',
        deleting: 'Удаляем...',
        archiving: 'Архивируем...',
        processing: 'Обрабатываем...',
        success: 'Успешно',
        userDeleted: 'Пользователь удалён',
        settingsSaved: 'Настройки сохранены',
        successCreatedBalance: 'Новый баланс успешно создан',
        successChangeBalance: 'Информация обновлена',
        successDeletedBalance: 'Баланс удалён',
        successArchivedBalance: 'Баланс архивирован',
        successAddedAssistant: 'Ассистент добавлен',
        successDeletedAssistant: 'Ассистент был удалён',
        successSubscribed: 'Подписаны',
        smthWrong: 'Что-то пошло не так',
        aiPrioritizing: 'AI приоритизация...',
        deleted: 'Удалено',
        copied: 'Скопировано',
      },
      tab: {
        exchangeHistory: 'История обменов',
        balanceHistory: 'История балансов',
        invitationsOptions: 'Параметры приглашения',
        invitationsHistory: 'История приглашений',
        alreadyReceived: 'Уже получил',
        wantToReceive: 'Хочу получить',
      },
      labels: {
        youGive: 'Вы отдаете',
        youGet: 'Вы получаете',
        youProvideTo: 'Ваши контакты',
        profit: 'Прибыль',
        totalIncome: 'Суммарный доход',
        cash: 'Наличные',
        transfer: 'Перевод',
        goods: 'Товары',
        currency: 'Валюты',
        rent: 'Аренда',
        role: 'Ваша роль',
        status: 'Статус',
        betterExchangeRate: 'лучший курс {{ rate }}',
        betterExchangeFrom: 'начинается с {{ amount }} {{ code }}',
        toArchive: 'в архив',
        total: 'Итого',
        clientGives: 'Клиент отдает',
        clientGets: 'Клиент получает',
        title: 'Заголовок',
        comment: 'Комментарий',
        type: 'Тип',
      },
      placeholder: {
        comment: 'Введите ваш комментарий...',
      },
      error: {
        comment: 'Введите комментарий',
        value: 'Введите сумму',
        code: 'Введите код валюты',
        unknown: 'Произошла ошибка',
      },
      menu: {
        history: 'История',
        home: 'Домашняя',
        exchange: 'Сделки',
        friends: 'Окружение',
        profile: 'Проекты',
        back: 'Назад',
        task: 'Поток',
      },
      component: {
        provider: 'Provider',
        yourProvider: {
          title: 'Ваш провайдер',
          empty: 'У вас нет провайдера',
        },
        exchangeProvider: 'Провайдер',
        assistants: {
          empty: 'У вас нет помощников',
        },
        providersSlider: {
          rideTitle: 'Прокатись на',
          ride: 'машине',
          text: 'ваш провайдер',
          yourOffer: 'Здесь вы можете видеть предложение, которое вы формируете для своих клиентов',
        },
        customerLanguage: {
          title: 'Язык кастомера',
          optional: 'необязательный',
          list: {
            unspecified: 'Не указано',
            english: 'Английский',
            russian: 'Русский',
          },
        },
        reusableInvite: {
          title: 'Групповое приглашение'
        },
        invitation: {
          createdAt: 'Создано',
          inviteCode: 'Код-приглашение',
          accepted: 'Принято',
          expired: 'Истекший',
          recommendation: 'Рекомендация',
          remainingTime: 'Оставшееся время',
          emptyList: 'По кнопке сгенерируется код-приглашение. Этот код нужно скопировать и вместе с ссылкой на приложение отправить контакту через любой мессенджер.',
          copied: 'Скопировано',
        },
        balanceSummary: {
          with: 'c {{name}}',
          deligated: 'делегировано {{name}}',
        },
        transaction: {
          added: 'изменено {{name}}',
        },
      },
      date: {
        days: 'дней',
        today: 'Сегодня',
        January: 'Январь',
        February: 'Февраль',
        March: 'Март',
        April: 'Апрель',
        May: 'Май',
        June: 'Июнь',
        July: 'Июль',
        August: 'Август',
        September: 'Сентябрь',
        October: 'Октябрь',
        November: 'Ноябрь',
        December: 'Декабрь',
      },
      time: {
        short: {
          hour: 'ч',
          minute: 'м',
        },
      },
      page: {
        main: {
          step1: '1. Скопируйте ваш код-приглашение',
          invitationCodeCopied: 'Код скопирован',
          step2Desktop: '2. Войдите через телеграм',
          step2Touch: '2. Установите приложение',
          step3: '3. Перейдите в Сеть <arrow/> <tag/>Ввести код-приглашение',
        },
        provider: {
          title: 'Провайдер',
          trustMoneyInfo: 'Сколько денег вы бы доверили {{providerName}}?',
          trustMoneyDescription: 'Выберите сумму денег, чтобы оценить ваше доверие',
        },
        exchange: {
          title: 'Сделки',
        },
        exchangeInfo: {
          title: 'Подтверждение',
          receptionAccount: {
            title: 'Счёт получателя',
            placeholder:
              'Здесь вы можете ввести информацию о своем счёте (например: номер кредитной карты, телефон, крипто кошелёк)',
          },
          preparedText: 'У меня есть {{currency}} готовые к отправке',
          deadlineLabel: 'Срок получения',
          goods: {
            title: 'Подтверждение',
            in: 'в',
            city: 'Город',
          },
          provider: 'Провайдер',
          timer: 'Предложение будет актуальным',
          alreadyCreated: 'Сделка уже создана',
          customer: 'Клиент',
          inviteLink: 'Ссылка-приглашение',
          contact: 'Контакт'
        },
        success: {
          title: 'Обмен создан',
          tmInfo: 'Ваш Telegram-чат с провайдером создан автоматически.',
          tmInfoButton: 'Нажмите на кнопку ниже, чтобы войти в чат и обсудить детали транзакции.',
        },
        friends: {
          title: 'Окружение',
          empty: 'Connect App - закрытая соцсеть, по приглашениям. Добавьте сюда всех людей вокруг, чтобы держать с ними денежные балансы, сделки и задачи в порядке. Сгенерировать код-приглашение можно по кнопке “+” в верхней части экрана.',
          search: 'Поиск...',
        },
        contactProfile: {
          title: 'Профиль контакта',
          feeRanges: {
            title: 'Размеры комиссий',
            feeRatesTable: {
              caption: 'Нажмите на ячейку для редактирования',
              thead: {
                lowerBound: 'Нижняя граница',
                feeRate: 'Размер комиссии',
              },
            },
          },
          balancesHistory: {
            emptyListPlaceholder: "Здесь пока ничего нет"
          },
          paymentsHistory: {
            emptyListPlaceholder: "Здесь пока ничего нет"
          }
        },
        payments: {
          title: 'Сделки',
          empty: 'Список пустой',
        },
        paymentInfo: {
          title: 'Платеж',
          infoTab: 'Информация',
          historyTab: 'История',
          titleId: 'Заголовок',
          id: 'Номер',
          status: 'Статус',
          providerFeeRate: 'Комиссия провайдера',
          providerFeeAmount: 'Сумма комиссии',
          creationDate: 'Дата создания платежа',
          deadline: 'Срок платежа',
          customer: 'Клиент',
          requester: {
            gives: 'Контакт отдает',
          },
          provider: {
            gives: 'Провайдер отдает',
          },
          chatHistory: 'История чата',
        },
        paymentChatHistory: {
          notFound: 'История чата сделки не найдена',
        },
        profile: {
          title: 'Проекты',
          menu: {
            uploadCurrencyFile: 'Загрузить файл',
            upgradeToProvider: 'Стать провайдером',
            maintenance: 'Мэйнтейнанс',
            allPayments: 'Платежи',
            changeLanguage: 'Выбрать язык',
            logout: 'Выйти',
            redeemInvitation: 'Ввести код-приглашение',
            feeRangeDefaults: 'Шаблон коммисий',
            assistants: 'Помощники',
            subscriptions: 'Push-подписки',
            deleteAccount: 'Удалить аккаунт',
            notifications: 'Оповещения',
            exchangeRates: 'Курсы обмена',
            myFees: 'Мои курсы',
            myOffers: 'Мои предложения',
            tasks: 'Задачи',
          },
          noProjects: "У вас еще нет ни одного проекта"
        },
        invitations: {
          title: 'Приглашения',
          shareButton: 'Поделиться',
          shareText: 'Ссылка-приглашение в Connect✨App'
        },
        redeemInvitation: {
          title: 'Код-приглашение',
          button: 'Использовать код',
          inputPlaceholder: 'Введите код',
          successMessage: 'Код успешно активирован',
        },
        feeRangesDefault: {
          title: 'Шаблон коммисий',
        },
        login: {
          pasteInviteCode: 'Вставить код',
          helpText:
            'Нажмите кнопку "Вставить" или введите код-приглашение вручную, если он у вас есть',
        },
        assistants: {
          mainAssistants: 'Основные ассистенты',
          noAssistants: 'Нет ассистентов',
        },
        exchangeRates: {
          title: {
            main: 'Курсы обмена',
            you: 'Вы',
            availableProviders: 'Доступные провайдеры',
          },
          loading: 'Загрузка...',
          empty: 'У вас нет провайдеров',
          provider: {
            empty: 'У этого провайдера нет курсов',
          },
          resellStrategy: 'Способ перепродажи',
        },
        subscriptions: {
          title: 'Push-подписки',
        },
        balanceAssistants: {
          title: 'Ассистенты баланса',
          currentAssistants: 'Текущие ассистенты',
          otherContacts: 'Другие контакты',
          currentAssistantsEmpty: 'Нет ассистентов',
          contactsNotFound: 'Контакты не найдены',
          search: 'Поиск...',
        },
        balanceHistory: {
          title: 'История баланса',
        },
        task: {
          header: {
            button: 'Добавить задачу в ИИ',
          },
          mainTask: {
            noTasksTitle: 'Здесь всегда будет Ваша самая важная задача',
            noTaskDescription: 'Добавьте сразу 2-3 задачи в ИИ, чтобы он определил, с чего начать. Старайтесь все новые задачи сначала отправлять в ИИ, а он уже знает все “джидайские” практики и определит правильную последовательность. Это позволит избежать затрат времени на второстепенные задачи и их выбор, сосредоточив Вас всегда на главном.',
            createButton: 'Добавить свою любую первую задачу в ИИ',
            prioritizeButton: 'Показать самую важную',
            reprioritizeButton: 'Приоритезировать точнее',
            prioritizeButtonDisabled: 'AI приоритетизация...',
            aiPrioritizedTitle: 'отприоритизировано AI',
            aiPrioritizedDescription: 'Ваш следующий шаг',
            notPrioritizedTasksTitle: 'Количество задач в очереди: ',
            notPrioritizedTasksDescription: 'Нажмите на кнопку ниже, чтобы определить самую важную задачу',
          },
          nextTask: 'Посмотрите все задачи',
          seeAll: 'Посмотреть все',
          list: {
            draft: 'Черновые задачи',
            recently: 'Недавно добавлены',
            blocked: 'Заблокированные задачи',
            unblock: 'Разблокировать',
            setTask: 'Доформулировать',
            prioritize: 'Приоритизировать',
            transactional: 'Транзакционные задачи',
            delegated: 'Делегированные задачи',
            emptyRecentlyAdded: 'У вас нет недавно добавленных задач'
          },
        },
        taskHistory: {
          title: 'История задачи',
          empty: 'Здесь пока ничего нет'
        },
        taskInfo: {
          originalDescription: 'Оригинальное описание',
          description: 'Описание',
          aiProposed: 'Предложено AI',
          aiPrioritizedDescription: 'Ваша задача сейчас',
          whyImportantNow: 'Почему это важно сейчас?',
          postponeComment: 'Причина блокировки',
          status: 'Статус',
          implementer: 'Исполнитель',
          reviewer: 'Автор',
          statuses: {
            inProgress: 'В работе',
            readyForWork: 'Готова к работе',
            draft: 'На этапе формулировки',
            blocked: 'Заблокирована',
            done: 'Завершена'
          },
          buttons: {
            delete: 'Удалить',
            postpone: 'Пометить как заблокированная',
            unblock: 'Разблокировать',
            addNewTask: 'Добавить новую задачу',
            setTask: 'Определить задачу',
            setTaskAssessment: 'AI оценка...',
            complete: 'Завершить',
            close: 'Закрыть',
            stillInProgress: 'Все еще в работе',
            takeOn: 'Взять задачу на себя',
            delegate: 'Передать задачу'
          }
        },
        tasks: {
          tabNextTasks: 'Следующие задачи',
          tabDelegatedTasks: 'Делегированные задачи',
          tabCompleted: 'Завершенные',
          emptyNextTasks: 'У вас нет следующих задач',
          emptyDelegatedTasks: "У вас нет делегированных задач",
          emptyCompletedTasks: 'У вас нет выполненных задач',
          aiPrioritized: 'AI приоритет',
          addTaskButton: 'Добавить задачу в ИИ',
          unprioritized: 'Без приоритета',
          blocked: 'Заблокированные',
          toBeFormulated: 'На этапе формулировки',
        },
        notifications: {
          title: 'Оповещения',
          switchOff: 'Отключить все',
          type: 'Тип оповещения',
          bot: 'Бот',
          push: 'Push',
          balances: 'Балансы',
          exchanges: 'Обмены',
          invitations: 'Приглашения',
          bulk: 'Системные',
          task: 'Задачи'
        }
      },
      install: {
        pwa: 'Установите PWA (Progressive Web App)',
        yourCode: 'ваш код-приглашение',
        copy: 'Скопировать код',
        openSafari: 'Теперь откройте Safari, пожалуйста',
        tapOnShareButton: 'Нажмите кнопку "Поделиться"',
        hint: 'Скопируйте код-приглашение, пожалуйста.',
        installPWA: 'Установить приложение',
        alreadyInSafari: 'Я уже в Safari',
        instruction: 'Инструкция для установки<br><strong>Connect PWA</strong>',
        step1: '1. Откройте эту страницу в Safari',
        step2: '2. Нажмите Поделиться',
        step3: '3. Нажмите <strong>На экран "Домой"</strong>',
        step4: '4. Нажмите <strong>Добавить</strong>',
        video: 'Видео инструкция',
        skip: 'пропустить установку',
      },
      rentButton: {
        day: 'сутки',
        days: 'суток',
      },
      resellStrategy: {
        default: 'По умолчанию',
        chainPayments: 'Цепные сделки',
        squashPayments: 'Объединённые сделки',
      },
      modal: {
        balanceInfo: {
          emptyNameError: 'Имя не может быть пустым',
          namePlaceholder: 'Введите имя нового баланса...',
          saveButton: 'Сохранить',
          cancelButton: 'не сейчас',
        },
        balanceRemove: {
          questionDelete: 'Вы уверены, что хотите удалить "{{balanceName}}"?',
          questionArchive: 'Вы уверены, что хотите заархивировать "{{balanceName}}"?',
        },
        obligationTransfer: {
          selectRecipient: 'Выберите получателя',
          empty: 'Получатели не найдены',
          error:
            'Проблема с балансом {{balanceName}}, id={{id}}, не могу найти другого участника баланса',
        },
        callMeBack: {
          title: 'Заполните форму<br>для обратного звонка',
          thanks: 'Спасибо!',
          thanksDescription:
            'В ближайшее время наш представитель позвонит вам и проконсультирует по интересующим вас вопросам. А пока вы можете заглянуть в наши Instagram и X',
          policyPrivacy:
            'Согласие на наши <a>Условия предоставления услуг<br>и Уведомление о конфиденциальности</a>',
          button: 'Позвоните мне',
          loadingButton: 'Отправка',
          namePlaceholder: 'Ваше имя',
          phonePlaceholder: 'Телефон',
        },
        update: {
          title: 'Найдена новая версия приложения',
          description: 'Вы используете устаревшую версию, пожалуйста, обновите приложение',
          updateButton: 'Обновить',
          closeButton: 'Закрыть',
        },
        taskFormulation: {
          cancelButton: 'Отмена',
          createDraftButton: 'Сохранить задачу',
          initialCase: 'Опишите задачу общими словами',
          nextButton: 'Далее',
          confirmButton: 'Сохранить',
          qAndA: 'Вопросы и ответы'
        },
        stillInProgress: {
          closeButton: 'Закрыть',
          nextButton: 'Далее',
          success: 'Успешно',
          postponedTo: 'Отложено до: '
        },
        createPaymentInvitation: {
          chooseContact: 'Выбрать контакт',
          confirmation: 'Подтверждение',
          shareInvite: 'Поделиться приглашением',
          copied: 'Скопировано',
          createInvite: 'Создать приглашение',
          inviteWithLink: 'Пригласить по ссылке',
          close: 'Закрыть',
          cancel: 'Отмена',
          empty: 'Клиент не найден',
        },
        postponeTask: {
          description: 'Укажите причину блокировки',
          confirmButton: 'Подтвердить',
          cancelButton: 'Отмена',
          errorMessage: 'Заполните комментарий'
        },
        delegateTask: {
          chooseContact: 'Выберите контакт',
          closeButton: 'Закрыть',
          success: 'Успешно'
        }
      },
      notification: {
        subscribe: 'Подписаться',
        unsubscribe: 'Отписаться',
        notPwa: 'Это приложение не PWA',
        denied: 'Уведомления запрещены',
        notSupported: 'Уведомления неподдержаны',
        banner: 'Разрешить Push-уведомления',
      },
    },
  },
};
