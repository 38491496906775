import styles from './Info.module.scss';
import React, { useMemo } from 'react';
import Amount from 'components/Amount';
import { formatDate } from 'utils';
import { Avatar, AvatarSize } from 'shared/ui/Avatar/Avatar';
import {BalanceTransactionProps} from "../../BalancesHistory/BalanceTransaction";

function Info({
                balanceName,
                balancePhotoUrl,
                bucketName,
                transactionComment,
                transactionValue,
                transactionCreatedAt,
                authorName
              }: BalanceTransactionProps) {
  const formattedDate = useMemo(() => {
    const date = new Date(transactionCreatedAt);
    return formatDate(date);
  }, [transactionCreatedAt]);

  //[TODO]: CT-532 нет информации о телеграм чате в транзакции
  return (
    <div className={styles.wrap}>
      <div className={styles.header}>
        <span>{formattedDate}</span>
        <div>
          <Amount value={transactionValue} sign colored />
          <p className={styles.transactionBucket}>{bucketName}</p>
        </div>
      </div>

      <div className={styles.info}>
        <span>{authorName}</span>
        <div className={styles.balanceInfo}>
          <Avatar
            username={balanceName || ''}
            profilePictureUrl={balancePhotoUrl}
            size={AvatarSize.Small}
            className={styles.avatar}
          />
          <span>{balanceName}</span>
        </div>
        {/* {telegramChatLink && (
          <a href={telegramChatLink} target="_blank" rel="noreferrer">
            {telegramChatLink}
          </a>
        )} */}
        <p className={styles.transactionComment}>{transactionComment}</p>
      </div>
    </div>
  );
}

export default Info;
