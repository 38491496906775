import remove from 'lodash/remove';
import { queryClient } from 'shared/config/reactQuery';
import { createApi } from '@reduxjs/toolkit/query/react';
import {
  BalanceDto,
  BalanceHistoryResponseDtoV2,
  BalanceResponseDto,
  CreateBalanceTransactionRequest,
  PageBalanceResponseDto,
  PageTransactionResponseDto,
} from 'dtos';
import { baseQueryWithReauth } from '../fetchBaseQueryWithAuth';

type PageParams = {
  contactId: number;
  page?: number;
  limit?: number;
};

export const balancesApi = createApi({
  reducerPath: 'balancesApi',
  baseQuery: baseQueryWithReauth(true),
  tagTypes: ['Balance', 'Transaction'],
  endpoints: (builder) => ({
    getBalancesByContactId: builder.query<BalanceResponseDto[], number>({
      query: (contactId) => ({
        url: '/balances/v2',
        params: { contactId },
      }),
      providesTags: (result) =>
        result
          ? [...result.map(({ id }) => ({ type: 'Balance' as const, id })), 'Balance']
          : ['Balance'],
      // /**
      //  * Здесь обновляем урлы, потому что для фронта
      //  * не получается понять обновились они или нет на методе changeBalanceInfo
      //  */
      // transformResponse: (balances: BalanceResponseDto[]) => {
      //   const images: Record<string, string> = {};

      //   return balances.map(balance => {
      //     if (balance.balancePhotoUrl) {
      //       if (!images[balance.balancePhotoUrl]) {
      //         images[balance.balancePhotoUrl] = balance.balancePhotoUrl + `?ver=${Math.random()}`;
      //       }

      //       balance.balancePhotoUrl = images[balance.balancePhotoUrl];
      //     }

      //     return balance;
      //   });
      // }
    }),
    createBalance: builder.mutation<BalanceDto, number>({
      query: (contactId) => ({
        url: '/balances',
        method: 'POST',
        params: { contactId },
      }),
      invalidatesTags: ['Balance'],
      /**
       * Тут не приходят canEdit, canDelete, canArchive,
       * поэтому использовать такой способ нельзя, надо обновиться
       */
      // async onQueryStarted(contactId, { dispatch, queryFulfilled, getCacheEntry }) {
      //   try {
      //     await queryFulfilled;
      //     const {data} = getCacheEntry();
      //     debugger;
      //     /**
      //      * Самостоятельно вставляем ответ в данные
      //      */
      //     dispatch(
      //       balancesApi.util.updateQueryData('getBalancesByContactId', contactId, draft => {
      //         if (data?.id) {
      //           draft.push(data);
      //         }
      //       })
      //     );
      //   } catch { }
      // }
    }),
    changeBalanceInfo: builder.mutation<void, {
      balanceId: number,
      contactId: number,
      balanceName?: string,
      balancePhoto?: FormData,
    }>({
      query: ({ balanceId, balanceName, balancePhoto }) => ({
        url: '/balances/name-photo',
        method: 'PUT',
        params: { balanceId, balanceName },
        body: balancePhoto,
        formData: true,
      }),
      // /**
      //  * Произойдёт перезапрос данных балансов у контакта, в котором есть такой баланс
      //  */
      invalidatesTags: (_result, _error, arg) => [{ type: 'Balance', id: arg.balanceId }],
      // async onQueryStarted({ balanceId, balanceName, contactId }, { dispatch, queryFulfilled }) {
      //   try {
      //     await queryFulfilled;
      //     /**
      //      * Самостоятельно обновляем информацию о балансе, чтоб не ходить заново за данными из бэка
      //      * почему ещё нужно сделать так:
      //      * бэк присылает картинку по тому же адресу, поэтому у нас на фронте нет никакой информации, что она поменялась
      //      * добавляем небольшой хак, чтоб можно было запросить заново картинку
      //      */
      //     dispatch(
      //       balancesApi.util.updateQueryData('getBalancesByContactId', contactId, draft => {
      //         const balance = draft.find(b => b.id === balanceId)
      //         if (balance) {
      //           balance.balanceName = balanceName;
      //           balance.balancePhotoUrl = balance.balancePhotoUrl + `?ver=${Math.random()}`;
      //         }
      //       })
      //     )
      //   } catch { }
      // },
    }),
    archiveBalance: builder.mutation<void, { contactId: number, balanceId: number }>({
      query: ({ balanceId }) => ({
        url: '/balances/archive',
        method: 'POST',
        params: { balanceId },
      }),
      // invalidatesTags: ['Balance'],
      async onQueryStarted({ balanceId, contactId }, { dispatch, queryFulfilled, getCacheEntry }) {
        try {
          await queryFulfilled;
          /**
           * Самостоятельно удаляем баланс у контакта
           */
          dispatch(
            balancesApi.util.updateQueryData('getBalancesByContactId', contactId, draft => {
              remove(draft, (balance) => balance.id === balanceId);
            })
          );
        } catch { }
      },
    }),
    getBalancesTransactionsByContactId: builder.query<PageTransactionResponseDto, PageParams>({
      query: (params) => ({
        url: '/balances/v2/transactions',
        params,
      }),
      providesTags: [{ type: 'Transaction' }],
    }),
    createBalanceTransaction: builder.mutation<void, CreateBalanceTransactionRequest>({
      query: (params) => ({
        url: '/balances/transactions',
        method: 'POST',
        body: params,
      }),
      invalidatesTags: (_result, _error, arg) => [{ type: 'Balance', id: arg.balanceId }, { type: 'Transaction' }],
      async onQueryStarted({ contactId }, { queryFulfilled }) {
        try {
          await queryFulfilled;
          // Пока делаем такой хак, надо переписать
          await queryClient.refetchQueries({
            queryKey: ['balances', contactId, 'history'],
            exact: true,
          });

          await queryClient.refetchQueries({
            queryKey: ['friends'],
            exact: true,
          });
        } catch { }
      }
    }),
    getAssistedBalances: builder.query<any, void>({
      query: () => ({
        url: '/balances/assisted',
      }),
      providesTags: (result) =>
        result
          // @ts-ignore
          ? [...result.map(({ id }) => ({ type: 'Balance' as const, id })), 'Balance']
          : ['Balance'],
    }),

    getBalanceTransactionsByBalanceIdV2: builder.query<BalanceHistoryResponseDtoV2, number>({
      query: (balanceId) => ({
        url: `/balances/v2/history/${balanceId}`,
      }),
      providesTags: [{ type: 'Transaction' }],
    }),

    updateBalancePriority: builder.mutation<void, number>({
      query: (balanceId) => ({
        url: '/balances/priority',
        method: 'POST',
        params: { balanceId },
      }),
      invalidatesTags: (_result, _error, arg) => [{ type: 'Balance', id: arg }],
    }),

    obligationTransfer: builder.mutation<void, { fromBalanceId: number, toBalanceId: number, comment: string, bucketName?: string, amount?: number }>({
      query: ({ fromBalanceId, toBalanceId, comment, bucketName, amount }) => ({
        url: '/balances/obligations-transfer',
        method: 'POST',
        params: { fromBalanceId, toBalanceId, comment, bucketName, amount },
      }),
      invalidatesTags: (_result, _error, arg) => [{ type: 'Balance', id: arg.fromBalanceId }],
    }),

    getBalancesForObligationTransfer: builder.query<PageBalanceResponseDto, { balanceId: number, searchString?: string, page: number, limit: number }>({
      query: (params) => ({
        url: '/balances/obligations-transfer',
        params,
      }),
      providesTags: (result) =>
        result?.content
          ? [...result.content.map(({ id }) => ({ type: 'Balance' as const, id: id })), 'Balance']
          : ['Balance'],
    }),

  }),
});

export const {
  useGetBalancesByContactIdQuery,
  useLazyGetBalancesByContactIdQuery,
  useCreateBalanceMutation,
  useChangeBalanceInfoMutation,
  useArchiveBalanceMutation,
  useCreateBalanceTransactionMutation,
  useGetBalancesTransactionsByContactIdQuery,
  useGetBalanceTransactionsByBalanceIdV2Query,

  useGetAssistedBalancesQuery,
  useUpdateBalancePriorityMutation,
  useObligationTransferMutation,
  useLazyGetBalancesForObligationTransferQuery,
} = balancesApi;
