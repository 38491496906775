import styles from './BalanceTransaction.module.scss';

import React from 'react';
import truncate from 'lodash/truncate';

import Amount from 'components/Amount';
import { Avatar, AvatarSize } from 'shared/ui/Avatar/Avatar';
import { formatDate } from 'utils';
import { useTranslation } from 'react-i18next';

export interface BalanceTransactionProps {
  balanceName?: string;
  balancePhotoUrl?: string;
  bucketName: string;
  transactionComment: string;
  transactionValue: number;
  transactionCreatedAt: string;
  authorName?: string;
}

function BalanceTransaction({
  balanceName,
  balancePhotoUrl,
  bucketName,
  transactionComment,
  transactionValue,
  transactionCreatedAt,
  authorName,
}: BalanceTransactionProps): JSX.Element {
  const { t } = useTranslation();
  const formattedDate: string = formatDate(new Date(transactionCreatedAt));

  return (
    <div className={styles.card}>
      <Avatar
        username={String(balanceName)}
        profilePictureUrl={balancePhotoUrl}
        size={AvatarSize.Small}
      />
      <div className={styles.info}>
        <p className={styles.comment}>{truncate(transactionComment, { length: 20, omission: '…' })}</p>
        <div className={styles.additionalInfo}>{balanceName}</div>
        <div className={styles.additionalInfo}>{formattedDate}</div>
      </div>
      <div className={styles.amount}>
        <div>
          <Amount value={transactionValue} sign colored />
          {bucketName.substring(0, 10)}
        </div>
        <p className={styles.authorName}>
          {t('component.transaction.added', { name: authorName })}
        </p>
      </div>
    </div>
  );
}

export default React.memo(BalanceTransaction);
