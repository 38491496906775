import React, { useMemo } from 'react';
import { Navigate } from 'react-router-dom';
import { NOT_FOUND } from 'pages/paths';
import {
  useGetBalanceTransactionsByBalanceIdV2Query,
} from 'services/balances';
import BalanceTransaction, {
  BalanceTransactionProps,
} from 'components/BalancesHistory/BalanceTransaction';
import BalanceCard from 'components/BalanceCard';

import { groupListByCreatedDate } from 'utils';
import SkeletonWithWrapper from 'shared/ui/SkeletonWithWrapper';
import { useTransactionInfoModal } from 'entities1/transactionInfoModal/services/useTransactionInfoModal';

import styles from './index.module.scss';
import TransactionInfoModal from '../TransactionInfoModal';

function BalanceHistory({ balanceId }: { balanceId: number }): JSX.Element {
  const { data, isLoading, isError } = useGetBalanceTransactionsByBalanceIdV2Query(balanceId);

  const { openModal } = useTransactionInfoModal();

  const openTransactionInfoModal =
    (b: BalanceTransactionProps) => (e: React.MouseEvent<HTMLLIElement>) => {
      e.preventDefault();
      openModal(b);
    };

  const groupedHistoryByCreatedDate = useMemo(() => {
    return groupListByCreatedDate(data?.transactions || []);
  }, [data?.transactions]);

  if (isError) {
    return <Navigate to={NOT_FOUND} />;
  }

  if (isLoading || data === undefined) {
    return (
      <div className={styles.empty}>
        <SkeletonWithWrapper height={60} count={3} containerClassName={styles.skeleton} />
      </div>
    );
  }

  return (
    <>
      <BalanceCard
        balance={data.balanceInfoDto}
        className={styles.balance}
        titleClassName={styles.balanceTitle}
      />
      <ul>
        {groupedHistoryByCreatedDate.map((b, idx) => {
          if (typeof b === 'string') {
            return <h3 key={b}>{b}</h3>;
          }

          const transactionProps: BalanceTransactionProps = {
            balanceName: data.balanceInfoDto.balanceName,
            balancePhotoUrl: data.balanceInfoDto.balancePhotoUrl,
            bucketName: b.bucketName,
            transactionComment: b.comment,
            transactionValue: b.value,
            transactionCreatedAt: b.createdAt,
            authorName: b.authorUser.username,
          };
          return (
            <li key={b.createdAt} onClick={openTransactionInfoModal(transactionProps)}>
              <BalanceTransaction {...transactionProps} />
            </li>
          );
        })}
      </ul>
      <TransactionInfoModal />
    </>
  );
}

export default BalanceHistory;
